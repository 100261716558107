import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import CheckDateBeforeToday from "features/projectCustomers/utils/CheckDateBeforeToday";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useState } from "react";
import { useEffect } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import handleError from "utils/handleError";
import * as yup from "yup";
import ReactLoading from "react-loading";

const AddProject = ({
  setAddProject,
  setReload,
  currProjectId,
  customer_id,
}) => {
  const [currProject, setCurrProject] = useState({});
  const user_id = useSelector((state) => state?.auth?.user_details.user.id);
  const { loading, fetchData } = useApi();
  const { loading: loadingList, fetchData: fectchList } = useApi();
  const {
    assignees,
    customers,
    intakes,
    projects,
    references,
    projectCustomerStatuses,
  } = useSelector((state) => state.base.base_data);

  const onSubmit = async (data) => {
    let result;
    if (currProjectId) {
      const endpoint = {
        method: "put",
        url: `/api/admin/project-customer/update/${currProjectId}`,
        data,
      };
      result = await fetchData(endpoint);
    } else {
      const endpoint = {
        method: "post",
        url: "/api/admin/project-customer/create",
        data,
      };
      result = await fetchData(endpoint);
    }
    if (result.success) {
      setReload((pre) => !pre);
      setAddProject(false);
    } else {
      const errors = handleError(result);
      errors?.map((err) => toast.error(err));
    }
  };

  const initialValues = {
    project_id: "",
    customer_id: customer_id || "",
    project_customer_status_id: "",
    intake_id: "",
    assignees: user_id ? [user_id] : [],
    reference_id: "",
    start_date: null,
    end_date: null,
    progress: 0,
    description: "",

    academic_doc: 0,
    academic_doc_receive_date: null,

    passport: 0,
    passport_receive_date: null,

    cv: 0,
    cv_receive_date: null,

    photo: 0,
    photo_receive_date: null,

    medical_certificate: 0,
    medical_certificate_receive_date: null,

    police_clearance: 0,
    police_clearance_receive_date: null,

    job_doc: 0,
    job_doc_receive_date: null,

    tax_doc: 0,
    tax_doc_receive_date: null,
    elp_doc: 0,
    elp_doc_receive_date: null,
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        if (key === "assignees") {
          obj[key] = data[key].map((user) => user.user_id);
        } else {
          obj[key] = data[key];
        }
      }
      return obj;
    } else return initialValues;
  };

  const validationSchema = yup.object({
    project_id: yup.string("").required("Project is Required").nullable(),
    customer_id: yup.string("").required("Customer is Required").nullable(),
    project_customer_status_id: yup
      .string("")
      .required("Status is Required")
      .nullable(),
    intake_id: yup.string("").required("Intake is Required").nullable(),
    assignees: yup.array().required("Assignee is Required").min(1),
  });

  const formik = useFormik({
    initialValues: getInitialValues(currProject),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    const getFullProjectCustomer = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/project-customer/show/${currProjectId}`,
      };
      const result = await fectchList(endpoint, false);
      result.success && setCurrProject(result?.data);
    };
    currProjectId && getFullProjectCustomer();
  }, []);
  return loadingList ? (
    <Box
      sx={{
        height: "calc(100vh - 163px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <Box sx={{ m: "-10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddProject(false)}
        >
          View Projects
        </Button>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 225px)",
          overflowY: "auto",
          pt: "15px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "15px",
          }}
        >
          <Select
            name="project_id"
            formik={formik}
            options={projects}
            label="Select Project *"
            selectProps={{ id: "id", label: "name" }}
          />
          <Select
            disabled
            options={customers}
            label="Select Customers *"
            selectProps={{ id: "id", label: "name" }}
            name="customer_id"
            formik={formik}
          />
          <Select
            options={projectCustomerStatuses}
            label="Status *"
            name="project_customer_status_id"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />
          <Select
            options={intakes}
            label="Intake *"
            name="intake_id"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />
          <MultiSelect
            name="assignees"
            formik={formik}
            options={assignees}
            label="Assigned *"
            selectProps={{
              id: "user_id",
              label: "name",
            }}
          />
          <Select
            options={references}
            label="References"
            name="reference_id"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />
          <DateTimePicker
            name="start_date"
            label="Start Date"
            formik={formik}
          />
          <DateTimePicker name="end_date" label="End Date" formik={formik} />
          <InputField
            name="description"
            label="Description"
            formik={formik}
            multiline
            rows={3}
            sx={{ gridColumn: "1 / -1" }}
          />
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Academic Documents"
              checked={Number(formik?.values?.academic_doc) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("academic_doc", e.target.checked ? 1 : 0);
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.academic_doc)
                  ? null
                  : formik?.values?.academic_doc_receive_date
              }
            >
              <DateTimePicker
                name="academic_doc_receive_date"
                label={
                  Number(formik?.values?.academic_doc)
                    ? "Receive Date"
                    : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Passport"
              checked={Number(formik?.values?.passport) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("passport", e.target.checked ? 1 : 0);
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.passport)
                  ? null
                  : formik?.values?.passport_receive_date
              }
            >
              <DateTimePicker
                name="passport_receive_date"
                label={
                  Number(formik?.values?.passport) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="CV"
              checked={Number(formik?.values?.cv) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("cv", e.target.checked ? 1 : 0);
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.cv)
                  ? null
                  : formik?.values?.cv_receive_date
              }
            >
              <DateTimePicker
                name="cv_receive_date"
                label={Number(formik?.values?.cv) ? "Receive Date" : "Due Date"}
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Photo"
              checked={Number(formik?.values?.photo) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("photo", e.target.checked ? 1 : 0);
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.photo)
                  ? null
                  : formik?.values?.photo_receive_date
              }
            >
              <DateTimePicker
                name="photo_receive_date"
                label={
                  Number(formik?.values?.photo) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Medical Certificate"
              checked={
                Number(formik?.values?.medical_certificate) ? true : false
              }
              onChange={(e) => {
                formik.setFieldValue(
                  "medical_certificate",
                  e.target.checked ? 1 : 0
                );
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.medical_certificate)
                  ? null
                  : formik?.values?.medical_certificate_receive_date
              }
            >
              <DateTimePicker
                name="medical_certificate_receive_date"
                label={
                  Number(formik?.values?.medical_certificate)
                    ? "Receive Date"
                    : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Police Clearance"
              checked={Number(formik?.values?.police_clearance) ? true : false}
              onChange={(e) => {
                formik.setFieldValue(
                  "police_clearance",
                  e.target.checked ? 1 : 0
                );
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.police_clearance)
                  ? null
                  : formik?.values?.police_clearance_receive_date
              }
            >
              <DateTimePicker
                name="police_clearance_receive_date"
                label={
                  Number(formik?.values?.police_clearance)
                    ? "Receive Date"
                    : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Job Document"
              checked={Number(formik?.values?.job_doc) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("job_doc", e.target.checked ? 1 : 0);
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.job_doc)
                  ? null
                  : formik?.values?.job_doc_receive_date
              }
            >
              <DateTimePicker
                name="job_doc_receive_date"
                label={
                  Number(formik?.values?.job_doc) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Tax Document"
              checked={Number(formik?.values?.tax_doc) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("tax_doc", e.target.checked ? 1 : 0);
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.tax_doc)
                  ? null
                  : formik?.values?.tax_doc_receive_date
              }
            >
              <DateTimePicker
                name="tax_doc_receive_date"
                label={
                  Number(formik?.values?.tax_doc) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="English language Proficiency  Document"
              checked={Number(formik?.values?.elp_doc) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("elp_doc", e.target.checked ? 1 : 0);
              }}
            />

            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.elp_doc)
                  ? null
                  : formik?.values?.elp_doc_receive_date
              }
            >
              <DateTimePicker
                name="elp_doc_receive_date"
                label={
                  Number(formik?.values?.elp_doc) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
        </Box>
        {/* <Box sx={{ marginTop: "25px" }}>
          <Typography variant="h5">
            Progress: {formik?.values?.progress}%
          </Typography>
          <Box sx={{ padding: "5px", pl: "10px" }}>
            <Slider
              value={formik?.values?.progress}
              min={0}
              step={1}
              max={100}
              onChange={(e, value) => {
                formik.setFieldValue("progress", Number(value));
              }}
              valueLabelDisplay="auto"
              aria-labelledby="non-linear-slider"
            />
          </Box>
        </Box> */}
      </Box>
      <Box
        sx={{
          paddingTop: "10px",
        }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {currProjectId ? "Update Project" : "Create Project"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddProject;
